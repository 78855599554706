<template>
	<div class="wrap">
		<div class="flex justify-end">
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="toEdit()">新增用户</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>

			<el-table-column prop="name" label="帐号" width="200"></el-table-column>
			<el-table-column prop="nickname" label="姓名" width="150"></el-table-column>

			<el-table-column prop="role_name" label="角色">
			</el-table-column>
			<el-table-column prop="status" label="状态" width="150">
				<template slot-scope="scope">
					<div class="text-gray" v-if="scope.row.status == 0">禁用</div>
					<div class="text-blue" v-if="scope.row.status == 1">启用</div>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="100">
				<template slot-scope="scope">
					<!-- <el-button size="mini" @click="resetPassword(scope.row)">重置密码</el-button> -->
					<el-button size="mini" @click="toEdit(scope.row)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: []
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			resetPassword(user) {
				this.$confirm(`您确定要重置${user.nickname}的密码吗？`, {
						type: "warning"
					})
					.then(() => {
						console.log(user);
						// const loading = this.$loading();
						// systemService.resetPassword(user.id).then(res => {
						// 	loading.close();
						// 	if (res.success) {
						// 		this.loadData();
						// 		this.$message.success("重置成功");
						// 	} else {
						// 		this.$message.error(res.msg);
						// 	}
						// });
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "操作已取消"
						});
					});
			},
			toEdit(user) {
				if (user) {
					// 编辑
					this.$router.push(`/systemUser/edit/${user.id}`);
				} else {
					// 新增
					this.$router.push("/systemUser/edit");
				}
			},
			loadData() {
				this.loading = true;
				this.$api.user.getUsers({page:1,page_size:1000}).then().then(res => {
					this.loading = false;
					this.tableData = res.data.data;
				});
			}
		}
	};
</script>
<style scoped>
	.el-table>>>.el-table__fixed-right::before {
		height: 0;
	}

	.el-table::before {
		height: 0;
	}
</style>
